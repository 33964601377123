.leafEdge {
  position: absolute;
  cursor: pointer;
  height: 40px;
  background: transparent;

  margin: auto;
  left: calc(50% - 10px); /* half of the width minus button width */

  /* inversion */
  transform: rotate(180deg);
}

.leafEdgeLine {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
  position: absolute;
  top: 3px;
  left: calc(50% - 0.5px);
  width: 1px;
  height: 100%;

  transition: opacity 0.2s ease-in-out;
  z-index: -1;
}
