.flowPreviewExampleSectionBlock {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;

  --tw-bg-opacity: 1 !important;
  padding: 0 !important;
}

.flowPreviewGraphContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  min-height: 300px;
}
