path.edgeLine {
  --tw-text-opacity: 1 !important;
  color: rgb(221 221 221 / var(--tw-text-opacity)) !important;
  stroke: currentcolor !important;
}

path.staticEdgeLine {
  --tw-text-opacity: 1 !important;
  color: rgb(221 221 221 / var(--tw-text-opacity)) !important;
  stroke: currentcolor !important;
}

.plusButton:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity)) !important;
}

.plusButtonActive {
  --tw-border-opacity: 1 !important;
  border-color: rgb(105 105 105 / var(--tw-border-opacity)) !important;

  --tw-bg-opacity: 1 !important;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity)) !important;

  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.edgeLinkName {
  border-radius: 9999px;
  border-width: 1px;

  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1.4;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}

.edgeLinkNameHoverState {
  cursor: pointer;
}

.edgeLinkNameHoverState:hover {
  --tw-border-opacity: 1;
  border-color: rgb(105 105 105 / var(--tw-border-opacity));
}

.edgeTwoButtons {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
}

.edgeTwoButtons > :first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.edgeTwoButtons > :nth-child(2) {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: -1px;
}

.linkMenu {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1.4;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  height: 38px;
  width: 80px;
}

.linkMenu div {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.linkMenu div:hover {
  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}

.linkMenu div {
  padding: 2px;
}

.linkMenu div svg {
  width: 12px;
  height: 12px;
  margin-right: 3px;
}

.linkMenu div:first-child,
.linkMenu div:last-child {
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.linkMenu div:first-child:hover,
.linkMenu div:last-child:hover {
  --tw-border-opacity: 1;
  border-color: rgb(105 105 105 / var(--tw-border-opacity));
}

.linkMenu div:first-child,
.linkMenu div:last-child {
  height: 19px;
}

.linkMenu div:first-child:not(:last-child) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  margin-bottom: -1px;
}

.linkMenu div:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.linkMenu div:first-child:hover {
  z-index: 10;
}

.linkMenuHidden {
  display: none;
}
