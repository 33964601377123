.loginAndTryFooter {
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 2rem;
  row-gap: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 0.25rem;
  background-color: rgb(15 76 117 / var(--tw-bg-opacity));
  color: rgb(255 255 255 / var(--tw-text-opacity));
  margin-bottom: 0;
  margin-top: 4rem;
  max-width: 64rem;
  padding: 1.5rem 1.5rem 1.25rem;
}

.loginAndTryFooter p {
  margin: 0;
  font-size: 1.188rem;
  line-height: 1.2;
}
