.figure {
  margin-top: 1.5rem;
  margin-bottom: 2.5rem;
}

.caption {
  display: inline-block;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));
  font-size: 0.813rem;
  line-height: 1.4;
  font-weight: 400;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  margin-left: 0.75rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.image {
  max-width: 100%;
}
.image__border {
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}
.image__caption {
  padding: 2rem;
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.popup {
  max-height: 100%;
  max-width: 100%;

  --tw-bg-opacity: 0 !important;
  padding: 2.5rem !important;
  width: auto !important;
  height: auto !important;
}

.popup img {
  border-width: 8px;

  --tw-border-opacity: 1;
  border-color: rgb(25 25 25 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(25 25 25 / var(--tw-bg-opacity));
  max-width: 100%;
}

/* Close Button */

.popup > button {
  fill: currentcolor !important;

  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.popup > button:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}
.popup_content {
  overflow: auto;
}
