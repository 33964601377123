.buttonActive {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 76 117 / var(--tw-bg-opacity)) !important;

  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.button {
  flex: 1 1 0%;
  justify-content: center;
}

.triggerPlaceholderNodeIcon {
  --tw-bg-opacity: 1;
  /* TODO: PL-7140 order of styles changed after files were moved and this style is not strong enough anymore */
  background-color: rgb(221 221 221 / var(--tw-bg-opacity)) !important;
}
