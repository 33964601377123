.header .anchor {
  opacity: 0;
  margin-left: -2rem;
  margin-right: 0.625rem;
  width: 1.25rem;
  padding-left: 0.375rem;
}
@media (min-width: 1024px) {
  .header .anchor {
    padding-left: 0;
  }
}
.header .anchor svg {
  height: 1.25rem;
  width: 1.25rem;
}
.header:hover .anchor {
  opacity: 1;
}
