.wrapper {
  z-index: 10;
  height: 100%;
  border-left-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.relWrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.panel {
  height: 100%;
  overflow-y: auto;
  padding: 1.5rem 2.25rem;
  display: flex;
  flex-direction: column;
}

.content {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
  padding: 0.75rem 2.25rem;
}

/* h1 */
.header {
  margin-bottom: 0;
  padding: 0;
}

/* h2 */
.subLevelHeader {
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;
}

/* h4 */
.subSubLevelHeader {
  margin-top: 1rem;
  margin-bottom: 0;
  padding: 0;
}

.subheader {
  margin-top: 0.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.3;
}

.header + .subLevelHeader,
.header + .subSubLevelHeader {
  margin-top: 1.5rem;
}

.header + .content,
.subLevelHeader + .content,
.subSubLevelHeader + .content {
  margin-top: 1.5rem;
}
.header + *[class^='_tabList'] {
  margin-top: 1.5rem;
}
.subLevelHeader + .subheader {
  margin-top: 0;
}
.subheader + .content {
  margin-top: 0.5rem;
  padding-top: 0;
}

.content:first-child:last-child,
.contentGrow {
  flex-grow: 1;
}

.content > *[class^='_addFieldButtonRootLevel']:first-child {
  padding-top: 0;
}
.content > *[class^='_nestedLevel']:first-child > *[class^='_rowWrapper'] {
  padding-top: 0;
}
