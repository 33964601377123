.nodesMenu {
  cursor: pointer;
  border-radius: 0.375rem;

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.selected {
  /* placeholder */
}

.nodesMenuItem {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  border-width: 1px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.4;
}

.nodesMenuItem:hover {
  --tw-border-opacity: 1;
  border-color: rgb(105 105 105 / var(--tw-border-opacity));
}

.nodesMenuItem {
  position: relative;
  margin-bottom: -1px;
}

.nodesMenuItem:hover {
  z-index: 500 !important;
}

.selected.nodesMenuItem {
  --tw-border-opacity: 1;
  border-color: rgb(105 105 105 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.nodesMenuItemNodeIcon {
  overflow: hidden;
  border-radius: 0.25rem;

  --tw-bg-opacity: 1;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  height: 1.25rem;
  width: 1.25rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected .nodesMenuItemNodeIcon {
  --tw-bg-opacity: 1;
  background-color: rgb(15 76 117 / var(--tw-bg-opacity));
}

.nodesMenuItemNodeIcon img {
  height: 0.75rem;
  width: 0.75rem;
}
