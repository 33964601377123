.node {
  width: 100%;

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  z-index: 2 !important;
  position: relative;
}

.nodeIcon {
  overflow: hidden;
  border-radius: 0.25rem;

  --tw-bg-opacity: 1;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  height: 2.25rem;
  width: 2.25rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nodeIcon img {
  height: 1.25rem;
  width: 1.25rem;
}

.nodeIconCombobox {
  overflow: hidden;
  border-radius: 0.25rem;

  --tw-bg-opacity: 1;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  height: 1.5rem;
  width: 1.5rem;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nodeIconCombobox img {
  height: 1rem;
  width: 1rem;
}

.nodeTitle {
  margin: 0;
  width: 100%;
  flex: 1 1 0%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  font-size: 1rem;
  line-height: 1.4;
  font-weight: 700;
}
.nodeTag {
  margin-top: 0.25rem;
  border-width: 0;
}

.nodeActive .nodeTag {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.nodeActive {
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity));
}
.nodeActive .nodeIcon {
  --tw-bg-opacity: 1;
  background-color: rgb(15 76 117 / var(--tw-bg-opacity));
}
.nodeActive .nodeTitle {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.nodeTagActive {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  background-color: rgb(13 121 194 / var(--tw-bg-opacity));

  --tw-bg-opacity: 0.5;
}

.nodeDeleteButton {
  position: absolute;
  top: 50%;
  right: -0.625rem;
  margin-top: -0.5rem;

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.nodeDeleteButton:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(168 54 5 / var(--tw-bg-opacity));
}

.nodeDeleteButton {
  width: 19px;
  height: 19px;
}

.node:hover,
.nodeHovered {
  --tw-border-opacity: 1;
  border-color: rgb(105 105 105 / var(--tw-border-opacity));
}

/* Trigger node variation */
.triggerNode {
  /* ... */
}

.triggerNode .nodeIcon {
  --tw-bg-opacity: 1;
  background-color: rgb(15 76 117 / var(--tw-bg-opacity));
}

/* Portal node variation */
.portalNode {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.portalNode .nodeIcon {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}

.portalNode .nodeTitle,
.portalNode .nodeTag {
  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}

/* Rounded button  */
.commonRoundedButton {
  --node-common-rounded-button-size: 20px;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border-radius: 9999px;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  display: flex;
  align-items: center;
  justify-content: center;
}
.commonRoundedButton:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(168 54 5 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.commonRoundedButton {
  min-width: var(--node-common-rounded-button-size);
  min-height: var(--node-common-rounded-button-size);
  max-width: var(--node-common-rounded-button-size);
  max-height: var(--node-common-rounded-button-size);
}

.commonRoundedButton svg {
  width: calc(var(--node-common-rounded-button-size) - 8px);
  height: calc(var(--node-common-rounded-button-size) - 8px);
  stroke-width: 2;
}

.commonRoundedButtonSquared {
  border-radius: 0.25rem !important;
}

.commonRoundedButtonTopRight {
  position: absolute;
  top: 0;
  right: -0.5rem;
  z-index: 10;
  margin-top: -0.5rem;
}

.node.disableHover {
  cursor: default;
}

.node.disableHover:hover {
  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

.nodeErrorDot {
  border-radius: 9999px;
  min-width: 10px;
  min-height: 10px;
  background-color: #dc4200;
}

.nodeStackItemTop {
  margin-bottom: -1px;
}

.nodeStackItemsTop > div {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.nodeStackItemsTopHovered > div {
  --tw-border-opacity: 1;
  border-color: rgb(105 105 105 / var(--tw-border-opacity));
}

.nodeStackItem {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
  padding: 0.5rem;
  font-size: 0.75rem;
  line-height: 1.4;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  position: relative;
}

.nodeStackItemBottom {
  margin-top: -1px;
}

.nodeStackItemBottom:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.nodeStackItemTop:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nodeStackItem .nodeErrorDot {
  min-width: 5px;
  min-height: 5px;
}

.nodeStackItemSelected {
  --tw-border-opacity: 1 !important;
  border-color: rgb(105 105 105 / var(--tw-border-opacity)) !important;

  --tw-bg-opacity: 1 !important;
  background-color: rgb(13 121 194 / var(--tw-bg-opacity)) !important;

  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
