:root .content {
  all: initial;
  font-family: Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans',
    Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7;
  font-weight: 400;
}

:root .content h1,
:root .content h2,
:root .content h3,
:root .content h4,
:root .content p,
:root .content ol,
:root .content ul {
  all: initial;
  font-family: Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans',
    Arial, sans-serif;
  font-size: 0.875rem;
  line-height: 1.7;
  font-weight: 400;
}

:root .content h1,
:root .content h2,
:root .content h3,
:root .content h4 {
  display: flex;
  align-items: center;
}

:root .content h1 {
  scroll-margin: 8rem;
}

:root .content h2,
:root .content h3,
:root .content h4 {
  scroll-margin: 6.5rem;
}

:root .content p,
:root .content ol,
:root .content ul {
  display: block;
}

:root .content h1 {
  font-size: 2rem;
  line-height: 1.4;
  font-weight: 500;
  margin-bottom: 1.75rem;
  margin-top: 3rem;
}

:root .content h2 {
  font-size: 1.5rem;
  line-height: 1.4;
  font-weight: 500;
  margin-top: 1.75rem;
  margin-bottom: 0.625rem;
}

:root .content h3 {
  font-size: 1.125rem;
  line-height: 1.4;
  font-weight: 500;
  margin-top: 1.75rem;
  margin-bottom: 0.625rem;
}

:root .content h4 {
  font-size: 0.875rem;
  line-height: 1.4;
  font-weight: 600;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

:root .content p {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

/* Adjust the margins of paragraphs in Blockquote/Callout element */
:root .content :global(.iac-alert__description) {
  & p:first-child {
    margin-top: 0;
  }

  & p:last-child {
    margin-bottom: 0;
  }
}

:root .content ol {
  list-style-type: decimal;
  margin-left: 1rem;
}

:root .content ol li {
  margin-top: 0.25rem;
}

:root .content ul li::before {
  content: '—';
  padding-right: 0.375rem;
}

:root .content ul li {
  margin-top: 0.25rem;
  list-style-type: none;
}

:root .content ul > li > ul {
  margin-left: 1rem;
}

:root .content a {
  --tw-text-opacity: 1;
  color: rgb(13 121 194 / var(--tw-text-opacity));
}

:root .content a:hover {
  text-decoration-line: underline;
  text-decoration-color: currentColor;
}

:is(:root .content) p + p {
  padding-top: 0;
}

:root .content code {
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(238 238 238 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  border-radius: 0.25rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  margin-left: 0.125rem;
  margin-right: 0.125rem;
  line-height: 1.3;
}

:root .content pre {
  white-space: pre;
  word-wrap: break-word;
  overflow: auto;
}

:root .content pre:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

:root .content pre code {
  background: initial;
  padding: initial;
  margin: initial;
  border-radius: initial;
}

:root .content > img {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

:root .content table:global(.stripe) {
  border-top-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
}

:root .content table:global(.stripe) thead td,
:root .content table:global(.stripe) th {
  text-align: left;
  font-weight: 700;
}

:root .content table:global(.stripe) td,
:root .content table:global(.stripe) th {
  border-bottom-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));
  padding: 0.875rem;
}

:root .content table:global(.stripe) td[nowrap],
:root .content table:global(.stripe) th[nowrap] {
  white-space: nowrap;
}

:root .content table:global(.stripe) tr td:first-child,
:root .content table:global(.stripe) tr th:first-child {
  padding-left: 0;
}

:root .content table:global(.stripe) tr td:last-child,
:root .content table:global(.stripe) tr th:last-child {
  padding-right: 0;
}

:root .content.consoleEmbeddedMarkdown h1:first-child,
:root .content.consoleEmbeddedMarkdown h2:first-child,
:root .content.consoleEmbeddedMarkdown h3:first-child {
  margin-top: 0.625rem !important;
}
