.tag,
.input {
  --tw-bg-opacity: 1;
  background-color: rgb(238 238 238 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
  vertical-align: middle;
  font-family: 'Space Mono', monospace;
  font-size: 0.75rem;
  font-weight: 400;
  border-radius: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.3rem;
  padding-bottom: 0.08rem;
  min-width: 4rem;
  height: 1.69rem;
  line-height: 1rem;
}

.input {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(34 40 49 / var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.tag {
  position: relative;
  display: inline-block;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: middle;
  cursor: inherit;
}
.tagEditable,
.tagEditable .input {
  padding-top: 0.05rem;
}
.tagEditable:hover {
  --tw-border-opacity: 1;
  border-color: rgb(34 40 49 / var(--tw-border-opacity));
}
.tagEditable:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(34 40 49 / var(--tw-border-opacity));
}
.tagEditable.tagEditableRequired,
.tagEditable.tagEditableRequired:focus-within {
  border-color: rgb(168 54 5 / var(--tw-border-opacity));

  --tw-border-opacity: 0.4;
  background-color: rgb(168 54 5 / var(--tw-bg-opacity));

  --tw-bg-opacity: 0.1;
}

.tagIcon {
  position: absolute;
  right: 0;
  top: -0.25rem;
  bottom: -0.25rem;
  width: 2rem;
  display: none;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.25rem;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));

  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(238 238 238 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from),
    #eee var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-to: #eee var(--tw-gradient-to-position);
}
.tagIcon svg {
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
}
.tag:hover .tagIcon {
  display: flex;
}

/** TagId **/
.tagId {
  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgb(170 170 170 / var(--tw-text-opacity));
  max-width: 100%;
  overflow: hidden;
}

.tagIdValue {
  position: relative;
  width: 100%;
}
.tagIdIcon {
  position: absolute;
  right: -0.5rem;
  top: -0.25rem;
  bottom: -0.25rem;
  width: 2.25rem;
  display: none;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0.25rem;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));

  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-to: rgb(248 248 248 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from),
    #f8f8f8 var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-to: #f8f8f8 var(--tw-gradient-to-position);
}
.tagIdIcon svg {
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.tagIdIconAlways,
.tagIdValue:hover .tagIdIcon {
  display: flex;
}

a .tagId:hover {
  cursor: pointer;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}
a .tagId:hover .tagIdIcon {
  --tw-gradient-to: rgb(238 238 238 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from),
    #eee var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-to: #eee var(--tw-gradient-to-position);
}

.tagIdClickable {
  cursor: pointer;
}

.tagIdClickable:hover {
  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}
