.menu {
  all: initial;
  font-family: Inter, Roboto, 'Helvetica Neue', 'Arial Nova', 'Nimbus Sans',
    Arial, sans-serif;
  font-size: 0.813rem;
  line-height: 1.4;
  font-weight: 400;

  --text-black: #000;
  --text-gray: #535252;
  --first-level-text: var(--text-black);
  --nested-level-text: var(--text-gray);
  --block-border: #e8e8e8;
  --block-border-active: #000;
  --bg-active: rgba(0, 0, 0, 0.03); /* #f8f8f8 */
  --padding-step: 1;
  --padding-step-offset: 12px;
  --border-offset: 7px;
  --border-offset-negative: calc(var(--border-offset) * -1);
}

.menu li {
  list-style-type: none;
}

.menuItem_title {
  display: block;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.375rem;
  padding-right: 1.75rem;
  border-radius: 0.125rem;
  margin-left: var(--border-offset-negative);
  margin-right: var(--border-offset-negative);
}

.menuItem_title__expandable {
  cursor: pointer;
}

a.menuItem_title:hover,
.menuItem_title.menuItem_title__expandable:hover,
.menuItem_title:has(+ .chevronButton:hover),
a.menuItem_title__active {
  background-color: var(--bg-active);
}

.menuItem_title__external {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.menuItem_title__external svg {
  height: 0.875rem;
  width: 0.875rem;
}

/* First level */
.menu > li {
  color: var(--first-level-text);
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.menu > li .menuItem_title {
  padding-left: 0.375rem;
}
.menu > li > ul {
  margin-top: 0.5rem;
}

/* Nested levels */
.menu ul {
  position: relative;
  color: var(--nested-level-text);
}
.menu ul::before {
  content: '';
  width: 1px;
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  background-color: var(--block-border);
}
.menu ul li {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.menu ul .menuItem_title {
  position: relative;
  padding-left: calc(
    var(--border-offset) + (var(--padding-step-offset) * var(--padding-step))
  );
}
.menu ul .menuItem_title__active::before {
  content: '';
  position: absolute;
  top: 0.375rem;
  bottom: 0.375rem;
  display: block;
  width: 1px;
  left: var(--border-offset);
  background-color: var(--block-border-active);
}
.menu ul a.menuItem_title {
}
.menu ul span.menuItem_title {
}

.menu ul ul {
  --padding-step: 2;
}
.menu ul ul ul {
  --padding-step: 4;
}

.menu ul ul ul ul {
  --padding-step: 5;
}

.menuItem {
  position: relative;
  display: block;
}

.chevronButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.125rem;
  border-radius: 9999px;
  top: 50%;
  margin-top: -0.625rem;
  height: 1.25rem;
  width: 1.25rem;
}

.chevronButton:hover {
  background-color: var(--bg-active);
}
.chevronButton_icon {
  height: 1rem;
  width: 1rem;

  --tw-text-opacity: 1;
  color: rgb(105 105 105 / var(--tw-text-opacity));
}
