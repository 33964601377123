.container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.siteHeader {
  --block-border: #e8e8e8;
  border-bottom: 1px solid var(--block-border);
  position: sticky;
  top: 0;
  z-index: 50;

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
.siteHeader_content {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  gap: 0.75rem;
}
@media (min-width: 768px) {
  .siteHeader_content {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    padding-top: 0;
    padding-bottom: 0;
    height: 77px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.site_main {
  --max-content-width: 680px;
  --sidebar-width: 220px;
  --block-border: #e8e8e8;
  --header-height: 77px;
  max-width: min(1440px, 100%);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

@media (min-width: 1024px) {
  .site_main {
    --sidebar-width: 270px;
  }
}

.layout_aside {
}
.layout_aside__opened {
  z-index: 60;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  padding: 1.75rem;
  padding-top: 0.5rem;
  overflow-y: auto;
}
@media (min-width: 640px) {
  .layout_aside,
  .layout_aside__opened {
    z-index: initial;
    width: var(--sidebar-width);
    height: calc(100vh - var(--header-height));
    top: calc(var(--header-height));
    position: sticky;
    padding-left: 1.75rem;
    padding-right: 1.75rem;
    padding-top: 1rem;
    padding-bottom: 4rem;
    overflow-y: auto;
  }
}

.aside_button {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 70;
  margin-left: auto;
  margin-top: 0.75rem;
  margin-right: 1.75rem;
  padding: 0.625rem;
  border-radius: 0.25rem;
  border-width: 1px;

  --tw-border-opacity: 1;
  border-color: rgb(221 221 221 / var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgb(248 248 248 / var(--tw-bg-opacity));
}

.aside_button:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(221 221 221 / var(--tw-bg-opacity));
}

@media (min-width: 640px) {
  .aside_button {
    display: none;
  }
}

.aside_content {
  display: none;
}

.layout_aside__opened .aside_content {
  display: block;
}

@media (min-width: 640px) {
  .aside_content {
    display: block;
  }
}

.layout_content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-shrink: 1;
  flex-grow: 1;
  max-width: 100%;
}

@media (min-width: 640px) {
  .layout_content {
    max-width: calc(100% - var(--sidebar-width));
  }
}
.content_main {
  flex-shrink: 1;
  flex-grow: 1;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  border-left: 1px solid var(--block-border);
  max-width: 100%;
}
.articleContent {
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
}
.articleContent h1,
.articleContent h2,
.articleContent h3,
.articleContent h4 {
  scroll-margin-top: 20rem;
}
.content_toc {
  display: none;
  position: sticky;
  top: calc(var(--header-height) + 2rem);
  width: var(--sidebar-width);
  margin-top: 5rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  height: calc(100vh - var(--header-height) - 2rem);
  overflow-y: auto;
}
@media (min-width: 1280px) {
  .content_toc {
    display: block;
  }
}

.menuButton_content {
  --size: 16px;
  position: relative;
  box-sizing: border-box;
  display: block;
  cursor: pointer;

  height: var(--size);
  width: var(--size);
}

.menuButton_content span {
  height: calc(var(--size) / 16);

  --gap: calc(var(--size) / 16);
  border-radius: var(--gap);

  --n_gap: calc(-1 * var(--gap));

  position: absolute;

  display: block;

  width: 100%;

  --tw-bg-opacity: 1;

  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  transition:
    transform 0.5s,
    opacity 0.5s;
  top: 50%;
  right: 0;
}

.menuButton__opened .menuButton_content span:nth-child(1) {
  transform: rotate(45deg) translate(var(--n_gap), var(--n_gap));
}

.menuButton__opened .menuButton_content span:nth-child(2) {
  opacity: 0;
  transform: translate(0, var(--n_gap)) scale(0.01);
}

.menuButton__opened .menuButton_content span:nth-child(3) {
  transform: rotate(-45deg) translate(var(--gap), var(--n_gap));
}

.menuButton__closed .menuButton_content span:nth-child(1) {
  transform: translate(0, calc(-1 * var(--size) / 2 + var(--gap)));
}

.menuButton__closed .menuButton_content span:nth-child(2) {
  transform: translate(0, var(--n_gap));
  opacity: 1;
}

.menuButton__closed .menuButton_content span:nth-child(3) {
  transform: translate(0, calc(var(--size) / 2 - var(--gap) * 2));
}
